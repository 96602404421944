<template>
  <v-card>
    <v-container fluid>
      <v-layout>
        <v-flex>
          <v-data-table
            :headers="headers"
            item-key="envioLoteEmailId"
            :hide-default-footer="enviosLoteItems.length < 10"
            :items="enviosLoteItems"
            :loading="loadingTable"
            loading-text="Cargando datos"
            class="elevation-1"
            :expanded.sync="expanded"
            dense
            show-expand
          >
            <!-- Expansion de usuario -->
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-icon
                @click="handleExpansion(item, isExpanded)"
                size="20"
              >
                {{ isExpanded ? chevronUpIcon : chevronDownIcon }}
              </v-icon>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row class="ml-n16">
                  <v-col class="py-0">
                    <strong style="padding-left: 80px">Fecha alta:</strong>
                    {{ item.fechaProceso }}
                  </v-col>
                  <v-col class="py-0">
                    <strong style="padding-left: 80px">Usuario alta:</strong>
                    {{ item.usuEnvio }}
                  </v-col>
                </v-row>
                <v-row class="ml-n16">
                  <v-col class="py-0">
                    <strong style="padding-left: 80px">Observación:</strong>
                    {{ item.observaciones }}
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row class="pb-0 mb-n10 mt-n10">
                  <v-col cols="12" md="10" align="start" class="pt-0">
                    <v-card-title class="pl-1 primary--text">{{
                      title
                    }}</v-card-title>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
          </v-data-table>
          <v-row>
            <v-col cols="12" class="text-right" align="end">
              <v-btn outlined @click="closeModal"> Cerrar </v-btn>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
export default {
  name: "HistorialEnviosMailLoteDeuda",
  props: {
    loteDeudaId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      title: `Historial de envíos por email`,
      loadingTable: false,
      chevronUpIcon: enums.icons.CHEVRON_UP,
      chevronDownIcon: enums.icons.CHEVRON_DOWN,
      enviosLoteItems: [],
      expanded: [],
      headers: [
        {
          text: "Destino",
          align: "start",
          sortable: false,
          value: "procesoEnvioNombre",
        },
        {
          text: "Entidad",
          align: "start",
          sortable: false,
          value: "entidadFinancieraNombre",
        },
        {
          text: "Fecha de envío",
          align: "start",
          sortable: false,
          value: "fechaEnvio",
        },
        {
          text: "Estado",
          sortable: false,
          value: "estadoDeEnvio",
        },
        {
          text: "Email",
          sortable: false,
          value: "email",
        },
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  created() {
    this.setHistorialEnvios();
  },
  methods: {
    ...mapActions({
      getHistorialEnviosXLote: "devengamientos/getHistorialEnviosXLote",
    }),
    async setHistorialEnvios() {
      const response = await this.getHistorialEnviosXLote(this.loteDeudaId);
      this.enviosLoteItems = response;
    },
    closeModal() {
      this.$emit("closeHistorialEnvios");
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
  },
};
</script>